.social-links {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    font-size: 1.2rem;
}

li {
    margin: 1em;
}

a, a:visited {
    color: #000;
}